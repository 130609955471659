import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import { Button } from '@/components/general/Button';
import { CtaButton } from '@/components/general/CtaButton';
import { verticalFadeIn } from '@/constants/motionProps';
import { getCtaButtonVariant } from '@/utils/getCtaButtonVariant';
import { getTextColorClass } from '@/utils/getTextColorClass';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { useIsClient } from 'usehooks-ts';

import type { MasonryCardProps } from './MasonryCard.types';

export const MasonryCard: FC<MasonryCardProps> = ({
  action,
  background,
  defaultTextColor,
  textPosition,
  showCtaButton,
  ctaButtonText,
  textColor,
  text = '',
  colSpan = 1,
  rowSpan = 1,
}) => {
  const isClient = useIsClient();

  const heightClass = rowSpan === 1 ? 'h-[280px]' : 'h-full';
  const defaultTextColorClass = getTextColorClass(defaultTextColor, 'text');

  const content = (
    <BackgroundWrapper
      background={background}
      wrapperProps={{
        className: clsx([
          heightClass,
          'group',
          'min-h-[280px]',
          'flex',
          'flex-col',
          'justify-end',
          'px-20',
          'py-12',
          'lg:h-full',
          'lg:px-40',
          'lg:py-20',
          {
            'justify-start': textPosition.vertical === 'top',
            'justify-center': textPosition.vertical === 'center',
            'justify-end': textPosition.vertical === 'bottom',
            'items-start': textPosition.horizontal === 'left',
            'items-center': textPosition.horizontal === 'center',
            'items-end': textPosition.horizontal === 'right',
          },
        ]),
      }}
      containerProps={{
        className: clsx([
          'flex',
          'flex-col',
          'gap-10',
          'lg:gap-20',
          {
            'justify-start': textPosition.vertical === 'top',
            'justify-center': textPosition.vertical === 'center',
            'justify-end': textPosition.vertical === 'bottom',
            'items-start': textPosition.horizontal === 'left',
            'items-center': textPosition.horizontal === 'center',
            'items-end': textPosition.horizontal === 'right',
          },
        ]),
      }}
    >
      {!!(isClient && text) && (
        <h2
          className={clsx([
            defaultTextColorClass,
            'w-fit',
            'text-24',
            'font-bold',
            'uppercase',
            'lg:text-28',
          ])}
          style={{ color: textColor }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}

      {!!(
        action &&
        action.type !== 'none' &&
        showCtaButton &&
        ctaButtonText
      ) && (
        <div
          className={clsx([
            'transition-all',
            'overflow-hidden',
            'lg:max-h-0',
            'lg:-mt-20',
            'lg:opacity-0',
            'lg:group-hover:mt-0',
            'lg:group-hover:opacity-100',
            'lg:group-hover:max-h-[99999999px]',
          ])}
        >
          {action.type === 'action' && (
            <CtaButton
              variant={getCtaButtonVariant(action, 'secondary')}
              type={action.type}
              action={action.action}
              className={clsx(['!px-32'])}
            >
              {ctaButtonText}
            </CtaButton>
          )}

          {action.type !== 'action' && (
            <Button
              variant={getCtaButtonVariant(action, 'secondary')}
              className={clsx(['!px-32'])}
            >
              {ctaButtonText}
            </Button>
          )}
        </div>
      )}
    </BackgroundWrapper>
  );

  if (action?.type === 'action')
    return (
      <motion.div
        variants={verticalFadeIn}
        initial="hidden"
        whileInView="visible"
        transition={{ delay: 0.3 }}
        viewport={{ once: true }}
        style={{
          gridColumn: `span ${colSpan}`,
          gridRow: `span ${rowSpan}`,
        }}
      >
        {isClient && content}
      </motion.div>
    );

  return (
    <motion.a
      variants={verticalFadeIn}
      href={action && action.type !== 'none' ? action.href : undefined}
      initial="hidden"
      whileInView="visible"
      transition={{ delay: 0.3 }}
      viewport={{ once: true }}
      style={{
        gridColumn: `span ${colSpan}`,
        gridRow: `span ${rowSpan}`,
      }}
    >
      {isClient && content}
    </motion.a>
  );
};
